import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyComponent } from './survey.component';
const routes: Routes = [
  {
    path: ':branchId/:userId/survey',
    component: SurveyComponent,
    data: {
      title: 'סקר שביעות רצון'
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
