import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./app.component.scss']
})

export class SurveyComponent implements OnInit {
  title = 'biteSurvey';
  serverUrl = "https://api.bite-tech.co.il/";
  userId: number;
  branchId:number;
  color:string;
  logo:string;
  header1:string;
  header2:string;
  submitted: boolean;
  surveyQuestions: any[];

  constructor(private router: Router,  private route: ActivatedRoute, private http: HttpClient
   ) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {         
        return;
      }
     // const url = evt.urlAfterRedirects;
      //  console.log('url is', url);
      window.scrollTo(0, 0);
    });

  }
  
  ngOnInit() {
    this.submitted = false;
    this.route.params.subscribe(params => {
      console.log("params",params);
     
      if (params && params["userId"] && params["branchId"]) {
        this.branchId =  params["branchId"];
        this.userId =  params["userId"];
        console.log("branchId", params["branchId"]);
        console.log("userId", params["userId"]);
        this.getSurveysData().subscribe((res)=>{
          console.log(res);
          this.surveyQuestions = res.activeQuestions;
          this.color = res.color;
          this.header1 = res.headerLine1;
          this.header2 = res.headerLine2;
          this.logo = res.logo;
          document.documentElement.style.setProperty(`--orange`, this.color);
        })
      }
     
    });
  }

  submit() {
    console.log("this.surveyQuestions", this.surveyQuestions);
     this.sendSurveysData(this.surveyQuestions).subscribe((res)=>{
      console.log(res);
      if (res) this.submitted = true;
    })
 /*   this.surveyQuestions.forEach((item)=>{
      item.Id=0;
      this.sendSurveysData(item).subscribe((res)=>{
        console.log(res);
        
      })
    })*/
   
  }

  public getSurveysData(): Observable<any> {
    return this.http.get(this.serverUrl + 
      'Survey/GetSurveysData?branchId=' + this.branchId + '&userId=' + this.userId);
  }

  public sendSurveysData(survey): Observable<any> {
   
    return this.http.post<any>(this.serverUrl +
      'Survey/AddSurveyData',  {
        survey: survey
      });
  }
}
