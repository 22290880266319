import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent   {
  title = 'biteSurvey';
 
  userId: number;
  branchId:number;
  surveyQuestions: any[];

  constructor(private router: Router,  private route: ActivatedRoute, private http: HttpClient
   ) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {         
        return;
      }
     // const url = evt.urlAfterRedirects;
      //  console.log('url is', url);
      window.scrollTo(0, 0);
    });

  }
  
   
   
}
