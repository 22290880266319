<div class="container" sryle="overflow: hidden;">
    <div class="row  ">
        <div class="col"></div>
        <div class="col-auto">
            <img style="height: 90px; max-width: 100%;" [src]="logo"/>
        </div>
        <div class="col"></div>
         
       </div>
    <div class="survey-container"  *ngIf="!submitted" >
        <h2 class=" mt-4 ">{{header1}},</h2>
        <h2 class="mb-4 ">{{header2}}</h2>
        <div class="question" *ngFor="let question of surveyQuestions">
            {{question.Description}}
            <div *ngIf="!question.IsFreeText && question.Rank == 0" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
               <!--  &#9734; &#9734; &#9734; &#9734; &#9734; -->
            </div>
            <div *ngIf="!question.IsFreeText && question.Rank == 1" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div> 
            </div>
            <div *ngIf="!question.IsFreeText && question.Rank == 2" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div> 
            </div>
            <div *ngIf="!question.IsFreeText && question.Rank == 3" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div> 
            </div>
            <div *ngIf="!question.IsFreeText && question.Rank == 4" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/StarGrey.svg"> 
                </div> 
            </div>
            <div *ngIf="!question.IsFreeText && question.Rank == 5" class="row star-rating">
                <div class="col-auto star" type="button" (click)="question.Rank=1;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=2;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=3;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=4;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div>
                <div class="col-auto star" type="button" (click)="question.Rank=5;">
                    <img class="img-fluid" src="assets/Star01.svg"> 
                </div> 
            </div>
            <textarea *ngIf="question.IsFreeText"  rows="4"
            class="form-control"[ngModel]="question.TextAnswer" 
            (ngModelChange)="question.TextAnswer = $event"
            style="background: #Fff;border: 1px solid #D3D3D3; box-sizing: border-box; height: 100px;">
          </textarea>
        </div>
       
      
        <!-- Add more questions as needed -->
       <div class="row mt-4">
        <div class="col">
    
        </div>
        <div class="col-auto">
            <button class="btn btn-primary "  [ngStyle]="{'background-color': color, 'border-color': color }"
            (click)="submit()">שליחה</button>
        </div>
       </div>
        
      </div>
      <div class="survey-container"  *ngIf="submitted" >
        <h2 class=" mt-4 ">קיבלנו את המשוב. תודה!</h2>
      </div>
      <div class="row" style="position: absolute; width: 100vw; bottom: 0;left: 0;">
        <div class="col-12 align-middle" style="text-align: center; height: 3rem;">
          <div style="margin-top: 10px; color: white;">
            Powered by 
            <a style="color: white" href="https://bite.co.il">Bite Technology</a>
          </div>
        </div>
      </div>
</div>
